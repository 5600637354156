<template>
    <section id="content">
        <div class="container">
            
            <div class="c-header">
                <h2>
                    All Staff
                    <small>A list of all members of staff</small>
                </h2>
            </div>

            <!-- Add button -->
            <button class="btn btn-float btn-danger m-btn" @click="addStaff"><i class="zmdi zmdi-plus"></i></button>


            <div class="card">

                <div class="card-body card-padding">
                    <div class="contacts clearfix row">
                        
                        <div class="col-md-3 col-sm-4 col-xs-6" v-for="worker in staff" :key="worker.id">
                            <div class="c-item">
                                
                                <router-link :to="'/staff/' + worker.id" class="ci-avatar">
                                    <VueLoadImage>
                                        <img slot="image" :src="worker.photo != '' ? worker.photo : 'img/placeholder.png'"/>
                                        <img slot="preloader" src="img/loading.gif"/>
                                        <img slot="error" src="img/placeholder.png"/>
                                    </VueLoadImage>
                                </router-link>

                                <div class="c-info">
                                    <strong>{{ worker.title + ' ' + worker.first_name + ' ' + worker.last_name + ' ' + worker.other_names }}</strong>
                                    <small>{{ worker.email_1 }}</small>
                                </div>

                                <div class="c-footer">
                                    <button class="waves-effect" @click="viewStaff(worker.id)">
                                        <i class="zmdi zmdi-person-add"></i> 
                                        VIEW
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <infinite-loading @infinite="loadStaff">
                        <span slot="no-more">No more staff found</span>
                    </infinite-loading>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading'
    import VueLoadImage from 'vue-load-image'
    export default {
        data(){
            return {
                staff: [],
            }
        },
        methods: {
            addStaff(){
                this.$router.push("/add")
            },
            viewStaff(staffId){
                this.$router.push("/staff/" + staffId)
            },
            loadStaff($state){
                this.$http.get("/staff/load", {
                    params: {
                        page: this.staff.length / 20 + 1
                    }
                }).then(response => {
                    if (response.data.total > 0) {
                        this.staff = this.staff.concat(response.data.staff)
                        $state.loaded();
                        if (this.staff.total / 20 === 10) {
                            $state.complete();
                        }
                    } else {
                        $state.complete();
                    }

                })
            },
        },
        components: {
            InfiniteLoading,
            VueLoadImage,
        },
    }
</script>